.bg {
  position: relative;
  will-change: opacity;

  &.loaded {
    & > .bg-image {
      opacity: 1;
    }
  }

  & > .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
    opacity: 0;
    transition: opacity 600ms ease;
    background-size: cover;
  }

  & > .bg-front {
    position: relative;
    z-index: 10;
  }
}
