@import "../ui/media.scss";

.cards {
  padding: 50px 0 70px 0;

  @include desktop {
    padding: 80px 0 100px 0;
  }

  #services {
    padding: 40px 0;
  }

  .wrapper:last-child {
    background-color: #f7f7f7;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
  }

  h1 {
    max-width: $desktop;
    margin: 0 auto 30px auto;
  }

  .cards-ingress {
    max-width: $desktop;
    margin: 0 auto 80px auto;

    // @include desktop {
    //   margin: 0 auto 100px auto;
    // }

    @include desktop {
      display: flex;
      justify-content: space-between;
    }

    .image {
      background-color: #fff;
    }

    .cards-ingress-text {
      @include desktop {
        padding: 0 70px 0 0;
        max-width: 680px;
      }
    }

    .cards-ingress-image {
      margin: 40px 30px 0 0;
      max-width: 300px;

      @include desktop {
        margin: 10px 0 0 0;
        width: 300px;
        flex: 0 0 auto;
      }
    }

    h1 {
      margin: 0 0 30px 0;
    }
  }

  ul {
    @media (min-width: 640px) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    @include tablet {
      margin: 24px 0 0 0;
    }
  }

  li {
    @media (min-width: 640px) {
      flex: 0 0 calc(50% - 15px);
      max-width: calc(50% - 15px);
    }

    @include desktop {
      flex: 0 0 calc(25% - 25px);
      max-width: calc(25% - 25px);
    }
  }

  .card {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin: 0 0 25px 0;
    overflow: hidden;
    background-color: #fff;
  }

  .card-top {
    position: relative;
    height: 0;
    padding: 0 0 60% 0;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  .card-bottom {
    padding: 20px 0;
    text-align: center;
  }
}
