@import "../ui/media.scss";

.mobile-menu {
  position: fixed;
  width: 70vw;
  height: 140vh;
  top: 0;
  bottom: 0;
  left: 0;
  transition: transform 300ms ease;
  background-color: white;
  z-index: 50;
  transform: translateX(-70vw);

  .logo-wrapper {
    padding: 16px;

    @include landscape {
      padding: 25px;
    }

    svg {
      display: block;
    }
  }

  .nav-links {
    position: relative;
  }

  li {
    border-bottom: 1px solid #eaeaea;

    &:first-child {
      border-top: 1px solid #eaeaea;
    }
  }

  ul button {
    color: #5c8df5;
    transition: color 300ms ease;
    width: 100%;
    font-size: 18px;
    text-align: left;
    padding: 10px 16px;
    font-weight: 500;

    @include landscape {
      padding: 12px 25px;
    }

    &:active,
    &:hover,
    &:focus {
      color: #459fff;
    }
  }

  // .action {
  //   position: absolute;
  //   bottom: -54px;
  //   right: 16px;
  // }
}

.menu-open {
  .mobile-menu {
    transform: translateX(0);
  }
}
