@import "./ui/media.scss";

:root {
  --text-color: #2e2d2e;
  --text-color-dark: #fff;
  --background-tone: #f2f4ff;
  --background: #f8faff;
  --background-dark: #3a3361;
  --text-color-dark-emphasized: #9dc2e2;
  --text-color-cite: #666;
  --background-border: #dde3f3;
  --background-card: #fff;
  --background-card-dark: #423a6f;
  --link: #5c8df5;
  --link-hovered: #459fff;
  --link-shadow: #326aa7;
  --link-secondary: #e6e7fb;
  --link-shadow-secondary: #9493d6;
  --default-border: #b9c0de;
  --input-default: #03166f;
  --input-focus: #4974d0;
  --success: #19b783;
  --error: #fd647b;
  --error-lighten: #e85e5e;
  --disabled: #778092;
  --pending: #778092;
  --white: #fff;
  --tone: #fcfcfc;
  --pale-grey: #f7f7f7;
  --pale-blue: #f5f7ff;
  --font-regular: 16px;
  --font-regular-tablet: 18px;
  --font-button: 13px;
  --font-button-tablet: 14px;
  --font-small: 14px;
  --font-large: 18px;
  --input-height: 38px;
  --input-line-height: 36px;
  --input-height-tablet: 42px;
  --input-line-height-tablet: 40px;
  --button-line-height: 38px;
  --button-line-height-tablet: 42px;
  --input-radius: 5px;
  --card-radius: 7px;
  --ghost-border: 2px;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.app {
  position: relative;
  min-height: 100vh;

  &.stripped {
    .mobile-menu,
    footer,
    header {
      display: none;
    }
  }

  main {
    padding: 60px 0 0 0;
    z-index: 10;
    position: relative;

    @include landscape {
      padding: 80px 0 0 0;
    }
  }

  .wrapper {
    padding: 0 16px;

    @include landscape {
      padding: 0 25px;
    }
  }

  section {
    max-width: $desktop-plus;
    margin: 0 auto;
  }
}

a {
  color: #5c8df5;
  transition: color 300ms ease;

  &:active,
  &:hover,
  &:focus {
    color: #459fff;
  }
}

// .button {
//   border: 0;
//   height: auto;
//   width: auto;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: transparent;
//   width: auto;
//   margin: 0;
//   padding: 0;
//   cursor: pointer;
//   text-transform: uppercase;
//   font-weight: 600;
//   font-size: 14px;
//   padding: 0 16px;
//   height: 42px;
//   line-height: 1;
//   border-radius: 5px;
//   color: white;
//   box-shadow: 0 4px 12px -7px #326aa7;
//   background-color: #5c8df5;
//   transition: background-color 300ms ease, box-shadow 300ms ease;

//   &.ghost {
//     background-color: transparent;
//   }

//   &:active,
//   &:hover,
//   &:focus {
//     box-shadow: 0 4px 12px -7px transparent;
//     outline: 0;
//     background-color: #459fff;
//   }

//   &.ghost {
//     border: 2px solid #5c8df5;
//     color: #5c8df5;
//     transition: color 300ms ease, box-shadow 300ms ease;

//     &:active,
//     &:hover,
//     &:focus {
//       color: #459fff;
//       background-color: transparent;
//     }
//   }
// }

.center {
  text-align: center;
}

#curtain {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  transition: opacity 300ms ease;
  opacity: 0;
}

.menu-open {
  #curtain {
    pointer-events: all;
    opacity: 1;
  }
}
