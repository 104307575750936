@import "../../../ui/media.scss";

.input-text {
  .input-frame {
    position: relative;

    & > div {
      pointer-events: none;
    }
  }

  .input-label {
    padding: 2px 10px 6px 10px;
    line-height: 1.4;
    font-size: var(--font-regular);
    font-weight: 500;
  }

  textarea,
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-width: 1px;
    border-style: solid;
    height: var(--input-height);
    line-height: var(--input-line-height);
    border-radius: var(--input-radius);
    display: inline-block;
    width: 100%;
    padding: 0 46px 0 9px;
    font-size: var(--font-regular);
    color: var(--text-color);
    background-color: var(--white);

    &:disabled {
      background-color: var(--white);
      color: var(--disabled);
      cursor: not-allowed;
      border-style: dashed;
    }

    @include tablet {
      height: var(--input-height-tablet);
      line-height: var(--input-line-height-tablet);
    }
  }

  textarea {
    height: 140px;
    line-height: 1.5;
    padding: 6px 46px 6px 9px;
  }

  input::-webkit-calendar-picker-indicator {
    display: none;
  }
}
