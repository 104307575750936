@import "../../ui/media.scss";

.draft-controls {
  position: absolute;
  top: -40px;
  left: 0;

  .controls-buttons {
    box-shadow: 0 4px 16px -7px #326aa7;
    white-space: nowrap;
    display: none;
  }

  &.with-controls {
    top: -70px;

    .controls-buttons {
      display: inline-block;
    }
  }

  .control-button {
    & > span {
      text-decoration: line-through;
    }
  }

  .control-button {
    // width: 31px;
    display: inline-block;
    text-align: center;
    background-color: var(--pale-grey);
    background-color: var(--white);
    border: 1px solid var(--default-border);
    border: 1px solid var(--link);
    border-right: 0;

    & > button {
      color: #345294;
      line-height: 1.8;
      padding: 0 8px;
      font-weight: 500;

      @include landscape {
        padding: 0 14px;
      }
    }

    &.active {
      background-color: #cae2f7;
      background-color: var(--link);

      & > button {
        color: var(--white);
      }
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-right: 1px solid var(--default-border);
      border-right: 1px solid var(--link);
    }
  }

  .controls-actions {
    display: inline-block;
  }

  &.with-controls {
    .controls-actions {
      margin: 6px 0;

      @include landscape {
        margin: 0 20px;
      }
    }
  }

  .controls-abort,
  .controls-save,
  .controls-restore {
    box-shadow: 0 4px 16px -7px #326aa7;
    display: inline-block;
    margin: 0 5px 0 0;

    & > button {
      border-radius: 5px;
      line-height: 1.8;
      padding: 0 14px;
      font-weight: 600;
      background-color: var(--link);
      color: var(--white);
      display: block;
    }
  }

  .controls-abort {
    & > button {
      background-color: var(--disabled);
      color: var(--white);
      pointer-events: none;
    }
  }

  &.is-stale {
    .controls-abort {
      & > button {
        background-color: var(--link);
        color: var(--white);
        pointer-events: all;
      }
    }
  }

  &.is-stale {
    .controls-save,
    .controls-restore {
      & > button {
        background-color: var(--disabled);
        color: var(--white);
        pointer-events: none;
      }
    }
  }
}
