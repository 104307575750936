@import "../../ui/media.scss";

.status-message {
  position: relative;
  margin: 4px 0 0 10px;

  .message,
  .placeholder {
    visibility: hidden;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    font-style: italic;
    line-height: 1.4;

    @include tablet {
      font-size: 14px;
    }
  }

  .message {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
  }
}
