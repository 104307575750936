@import "../../ui/media.scss";

.new-action {
  margin: 20px 0;
  .item-description {
    padding: 12px 20px 8px 20px;

    @include tablet {
      padding: 12px 50px 8px 50px;
    }
  }

  .item-worth,
  .item-name {
    padding: 12px 0 8px 0;
  }
}

.overview-item {
  line-height: 1.5;
  position: relative;

  .flex {
    display: flex;
  }

  .item-description {
    padding: 12px 20px 8px 20px;
    border: 1px solid white;
    border-bottom: 1px solid #eaeaea;

    @include tablet {
      padding: 12px 50px 8px 50px;
    }
  }

  .item-worth,
  .item-name {
    padding: 12px 0 8px 0;
    border: 1px solid white;
    border-bottom: 1px solid #eaeaea;
  }

  &:last-child {
    .item-description,
    .item-worth,
    .item-name {
      border-bottom: 1px solid black;
    }
  }

  .item-description {
    .remove-btn {
      right: 40px;
      top: 2px;
    }

    .edit-btn {
      top: 2px;
    }
  }

  .overview-items-editors {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    z-index: 3;

    & > div {
      display: flex;
    }
  }

  &.is-edit {
    .item-description,
    .item-worth,
    .item-name {
      border: 1px dashed var(--link);
      background-color: #f1f8ff;
    }

    .flex {
      visibility: hidden;
      pointer-events: none;
    }

    .overview-items-editors {
      display: block;
      pointer-events: all;
    }
  }
}

.overview-items {
  &.is-new,
  &.is-edit {
    .overview-item {
      pointer-events: none;
      opacity: 0.3;
    }

    .save-action {
      display: block;
    }
  }

  &.is-edit {
    .new-action {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  .new-action {
    .flex,
    .save-new-btn {
      display: none;
    }
  }

  &.is-new {
    .new-btn {
      margin: 5px 0;
      display: none;
    }

    .overview-total {
      pointer-events: none;
      opacity: 0.3;
    }

    .save-action {
      display: none;
    }

    .flex {
      display: flex;
    }

    .save-new-btn {
      margin: 5px 0;
      display: block;
    }

    .new-action {
      .item-name,
      .item-description,
      .item-worth {
        border: 1px dashed var(--link);
        background-color: #f1f8ff;
      }
    }
  }

  .overview-item.is-edit {
    pointer-events: all;
    opacity: 1;
  }

  .save-action {
    display: none;
    margin: 5px 0;
  }

  .overview-total {
    padding: 5px 0 0 0;
    text-align: right;

    .item-description {
      flex: 0 1 200px;
    }

    b {
      margin: 0 0 0 5px;
      font-size: 18px;
    }
  }
}
