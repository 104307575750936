@import "../../ui/media.scss";

.sign-absolute {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: var(--input-height);
  z-index: 10;

  @include tablet {
    width: var(--input-height-tablet);
  }
}

.sign-wrapper {
  position: absolute;
  right: 1px;
  top: 1px;
  height: 100%;
  height: calc(100% - 2px);
  width: var(--input-line-height);
  background-color: var(--pale-grey);
  border-left: 1px solid var(--default-border);
  border-top-right-radius: var(--input-radius);
  border-bottom-right-radius: var(--input-radius);
  z-index: 10;

  @include tablet {
    width: var(--input-line-height-tablet);
  }
}

.radiobuttons,
.checkboxes {
  .sign-flipper {
    height: calc(2px + 100%);
  }
}

.sign-flipper {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: var(--input-height);
  transform-style: preserve-3d;
  perspective: 1000px;
  transform-origin: center;
  pointer-events: none;
  z-index: 11;

  @include tablet {
    width: var(--input-height-tablet);
  }

  .sign-status,
  .sign {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-top-right-radius: var(--input-radius);
    border-bottom-right-radius: var(--input-radius);
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }

  .default {
    background-color: var(--white);
    padding: 9px;

    svg {
      fill: var(--link);
    }
  }

  .pending,
  .disabled,
  .success,
  .error {
    svg {
      fill: var(--white);
    }
  }

  .error {
    padding: 9px;
    background-color: var(--error);
  }

  .success {
    padding: 7px 7px 7px 8px;
    background-color: var(--success);
  }

  .disabled {
    padding: 10px 10px 10px 11px;
    background-color: var(--pending);
  }

  .pending {
    padding: 3px;
    background-color: var(--pending);

    @include tablet {
      padding: 5px;
    }
  }

  .sign-back,
  .sign-front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }

  .sign-front {
    transform: rotateY(0);
    z-index: 10;
  }

  .sign-back {
    transform: rotateY(180deg);
    z-index: 9;
  }
}

.icon-pending {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: top;
  margin: 0 0 0 4px;

  span {
    display: inline-block;
    position: absolute;
    left: 10px;
    top: 16px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: white;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-delay: 200ms;
    animation-direction: alternate;
    transform: translateY(5px);

    &:first-child {
      left: 2px;
      animation-delay: 100ms;
    }

    &:last-child {
      left: 18px;
      animation-delay: 300ms;
    }
  }

  &.active {
    span {
      animation-name: bump;
    }
  }
}

.sign.active {
  .pending {
    span {
      animation-name: bump;
    }
  }
}

.pending.sign.active {
  span {
    animation-name: bump;
  }
}

@keyframes bump {
  0% {
    transform: translateY(5px);
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
