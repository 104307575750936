@import "../ui/media.scss";

header {
  padding: 0 16px;
  height: 60px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  background-color: white;
  position: fixed;

  @include landscape {
    padding: 0 25px;
    height: 80px;
  }

  @include tablet {
    position: absolute;
  }

  & > section {
    display: flex;
    justify-content: center;
    max-width: $desktop-plus;
    margin: 0 auto;

    @include tablet {
      justify-content: space-between;
    }
  }

  .logo {
    height: 60px;
    display: block;
    padding: 15px 0 0 0;

    @include landscape {
      height: 80px;
      padding: 20px 0 0 0;
    }

    svg {
      width: 100px;

      @include landscape {
        width: 140px;
      }
    }
  }

  .logo-link {
    @include tablet {
      margin: 0 0 0 -1px;
    }
  }

  .open-menu {
    position: absolute;
    top: 14px;
    left: 16px;
    padding: 5px 8px;
    background-color: #eaeaea;
    border-radius: 5px;

    @include tablet {
      display: none;
    }

    & > div {
      height: 2px;
      background-color: #2e2d2e;
      margin: 4px 0;
      width: 20px;
    }
  }

  .header-links {
    display: none;

    @include tablet {
      display: flex;
    }

    button {
      margin: 0 0 0 25px;
    }

    a {
      display: block;
      padding: 0 25px;
      height: 80px;
      line-height: 80px;
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      // color: var(--text-color);

      // &:active,
      // &:hover,
      // &:focus {
      //   color: #459fff;
      // }
    }
  }
}
