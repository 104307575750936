@import "./media.scss";

:root {
  --text-color: #2e2d2e;
  --font-regular: 16px;
  --font-regular-tablet: 18px;
}

/* montserrat-100 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  src: local(""), url("../fonts/montserrat-v15-latin-100.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-100.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-100italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  src: local(""),
    url("../fonts/montserrat-v15-latin-100italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-100italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-200 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: local(""), url("../fonts/montserrat-v15-latin-200.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-200.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-200italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  src: local(""),
    url("../fonts/montserrat-v15-latin-200italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-200italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/montserrat-v15-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: local(""),
    url("../fonts/montserrat-v15-latin-300italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-300italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../fonts/montserrat-v15-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: local(""),
    url("../fonts/montserrat-v15-latin-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/montserrat-v15-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src: local(""),
    url("../fonts/montserrat-v15-latin-500italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-500italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/montserrat-v15-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  src: local(""),
    url("../fonts/montserrat-v15-latin-600italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-600italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/montserrat-v15-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: local(""),
    url("../fonts/montserrat-v15-latin-700italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/montserrat-v15-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  src: local(""),
    url("../fonts/montserrat-v15-latin-800italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-800italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-900italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  src: local(""),
    url("../fonts/montserrat-v15-latin-900italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-900italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-900 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../fonts/montserrat-v15-latin-900.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* playfair-display-regular - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../fonts/playfair-display-v21-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/playfair-display-v21-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-700italic - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 700;
  src: local(""),
    url("../fonts/playfair-display-v21-latin-700italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/playfair-display-v21-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-800italic - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 800;
  src: local(""),
    url("../fonts/playfair-display-v21-latin-800italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/playfair-display-v21-latin-800italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-900italic - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 900;
  src: local(""),
    url("../fonts/playfair-display-v21-latin-900italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/playfair-display-v21-latin-900italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.8;
  font-size: var(--font-regular);
  color: var(--text-color);
  font-family: "Montserrat", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include tablet {
    font-size: var(--font-regular-tablet);
  }
}

.dark-mode {
  color: var(--white);
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 300;
  line-height: 1.4;
  margin: 40px 0 14px 0;

  @include tablet {
    line-height: 1.4;
  }

  &:first-child {
    margin: 0 0 14px 0;
  }
}

a {
  color: var(--link);
  font-weight: 500;
  transition: color 300ms ease;
  outline: none;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    color: var(--link-hovered);
  }
}

p {
  margin: 14px 0;
  line-height: 1.8;

  &:first-child {
    margin: 0 0 14px 0;
  }
}

h1 {
  font-size: 30px;

  @include tablet {
    font-size: 34px;
  }
}

h2 {
  font-size: 20px;

  @include tablet {
    font-size: 26px;
  }
}

h3 {
  font-size: 20px;

  @include tablet {
    font-size: 22px;
  }
}

textarea,
a,
button,
input,
i {
  font-family: "Montserrat", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

i {
  font-style: italic;
}

b {
  font-weight: 700;
}

strong {
  font-weight: 900;
}

.playfair {
  font-family: "Playfair Display", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-style: italic;
  font-weight: 800;
}
