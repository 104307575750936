@import "../ui/media.scss";

#contact {
  max-width: $tablet;

  h2 {
    margin: 0 0 20px 0;
  }

  .contact-wrapper {
    @media (min-width: 400px) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    & > div:first-child {
      @media (min-width: 400px) {
        padding: 0 30px 0 0;
      }
    }
  }

  .image-wrapper {
    margin: 20px 0 0 0;
    overflow: hidden;
    border-radius: 10px;

    @media (min-width: 400px) {
      max-width: 300px;
      margin: 0;
      flex: 0 1 300px;
    }
  }

  ul {
    margin: 30px 0;
  }
}
