@import "../ui/media.scss";

.top-bg {
  background-color: #000;

  .bg-image {
    background-position: 40% 25%;

    @include landscape {
      background-position: 20% 25%;
    }

    @include tablet {
      background-position: 50% 25%;
    }
  }

  .bg-tone {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .bg-tone {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.9) 5%,
      rgba(0, 0, 0, 0.6) 50%,
      rgba(0, 0, 0, 0.9) 95%
    );

    @include landscape {
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.8) 5%,
        rgba(0, 0, 0, 0.4) 40%,
        rgba(0, 0, 0, 0.8) 95%
      );
    }

    @include tablet {
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.8) 5%,
        rgba(0, 0, 0, 0.2) 80%,
        rgba(0, 0, 0, 0.8) 95%
      );
    }
  }
}

.top {
  // background-color: #292c31;
  // background-image: url(../images/bath_2537x1477.jpg);
  min-height: 380px;
  position: relative;

  @include landscape {
    min-height: 350px;
  }

  @include tablet {
    min-height: 500px;
  }

  @include desktop {
    min-height: 450px;
  }

  .ingress {
    position: relative;
    z-index: 20;

    p {
    }

    span {
      opacity: 0.7;
      font-size: 14px;
      margin: 0 0 0 6px;
    }

    .playfair {
      font-size: 22px;
      white-space: nowrap;

      @include landscape {
        font-size: 30px;
      }

      @include desktop {
        font-size: 34px;
      }
    }

    section {
      padding: 60px 0 0 0;
      color: white;
    }

    .ingress-card {
      position: relative;

      @include landscape {
        max-width: 390px;
      }

      @include tablet {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 20px;
        padding: 25px;
        margin: 30px 0 0 0;
        max-width: 450px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      }

      @include desktop {
        max-width: 500px;
        padding: 20px 25px 40px 25px;
        // background-color: white;
        // color: var(--text-color);
      }
    }

    .image-wrapper {
      width: 100px;
      height: 100px;
      position: absolute;
      right: 0;
      bottom: -80px;
      z-index: 5;
      border: 3px solid white;
      border-radius: 50px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      overflow: hidden;

      @include tablet {
        width: 120px;
        height: 120px;
        right: 25px;
        bottom: -60px;
        border-radius: 60px;
        box-shadow: none;
      }
    }
  }

  .image {
    background-color: #000;
  }
}
