@import "../../ui/media.scss";

.logo {
  position: relative;

  .heavy {
    font-weight: 800;
    font-size: 110px;
    letter-spacing: -0.062em;
    text-anchor: start;
  }

  .light {
    font-weight: 300;
    font-size: 46px;
    // text-anchor: middle;
    text-anchor: start;
    letter-spacing: 0.56em;
  }

  path {
    stroke: black;
    stroke-width: 2px;
  }
}

.test {
  position: relative;
  min-height: 900px;

  .image {
    position: absolute;
    width: 100%;
    opacity: 0.5;
  }

  .logo {
    position: relative;
    top: 235px;
    left: 85px;

    svg {
      width: 436px;
    }

    text {
      fill: red;
    }

    path {
      stroke: red;
      stroke-width: 2px;
    }
  }
}
