.draft-text {
  position: relative;
  z-index: 10;
  min-width: 120px;

  &.display-inline {
    display: inline-block;
    min-width: 250px;
  }

  .draft-controls {
    display: none;
  }

  &.is-edit {
    .draft-controls {
      display: block;
    }

    .edit-btn {
      display: none;
    }

    .draft-backdrop {
      display: block;
    }
  }

  .draft-actions {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }

  .draft-editor {
    position: relative;
    z-index: 1;
  }

  .draft-backdrop {
    z-index: 2;
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border: 1px dashed var(--link);
    background-color: rgba(144, 214, 255, 0.2);
    pointer-events: none;
    display: none;
  }
}

.split-draft {
  .draft-controls.with-controls .controls-actions {
    margin: 10px 10px 10px 0;
  }
}
