@import "../../ui/media.scss";

.dates {
  margin: 50px 0 0 0;

  .dates-start,
  .dates-duration,
  .dates-info {
    position: relative;
    max-width: 240px;

    @include landscape {
      max-width: 2400px;
    }

    @include landscape {
      padding: 0 0 20px 45px;
    }

    b {
      font-size: 18px;
      margin: 0 0 0 5px;
    }

    & > svg {
      width: 30px;
      position: absolute;
      top: 3px;
      left: 0;
      display: none;

      @include landscape {
        display: block;
      }
    }
  }

  .dates-info {
    svg {
      top: -2px;
    }
  }
}
