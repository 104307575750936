@import "../ui/media.scss";

footer {
  padding: 14px 16px 0 16px;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  background-color: #222;
  border-top: 1px solid #000;
  color: #ddd;
  height: 60px;

  @include landscape {
    padding: 14px 25px 0 25px;
  }

  section {
    max-width: $desktop-plus;
    margin: 0 auto;
  }
}
