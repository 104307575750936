@import "../../ui/media.scss";

.offert-header {
  display: flex;
  flex-direction: column;

  h1 {
    order: 2;
    margin: 0 0 2px 0;
  }

  .draft-text {
    order: 1;
    margin: 40px 0 8px 0;
    font-size: 12px;
    color: #777;
    font-style: italic;
  }

  @include landscape {
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;

    h1 {
      order: 1;
      margin: 40px 0 8px 0;
    }

    .draft-text {
      margin: 0 0 11px 0;
      text-align: right;
      min-width: 250px;
      order: 2;
      font-size: 16px;
    }
  }
}

.offert {
  padding: 0 16px 100px 16px;
  font-size: 12px;

  @include landscape {
    padding: 0 25px 200px 25px;
    font-size: 14px;
  }

  .print {
    max-width: $desktop;
    margin: 0 auto;
  }

  .print-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    width: 100px;
    padding: 10px 0 0 0;

    @include landscape {
      width: 160px;
    }
  }

  hr {
    margin: 0;
    border: 0;
    border-top: 1px solid black;
  }

  .hidden {
    min-height: 20px;
    background-color: #ccc;
  }

  .table-wrapper {
    overflow: scroll;
  }

  .banner {
    display: flex;

    .item-name,
    .item-description,
    .item-worth {
      padding: 0 0 2px 0;
      border-bottom: 1px solid black;
      font-weight: 600;
    }

    .item-description {
      padding: 0 20px 2px 20px;

      @include tablet {
        padding: 0 50px 2px 50px;
      }
    }
  }

  .item-name {
    flex: 0 0 180px;
  }

  .item-description {
    flex: 1 0 200px;
    position: relative;
  }

  .item-worth {
    flex: 0 0 160px;
    text-align: right;
  }

  .text-right {
    text-align: right;
  }

  .print-split {
    padding: 50px 0;

    @include landscape {
      display: flex;
      justify-content: space-between;
    }
  }

  .print-split-text {
    position: relative;

    @include landscape {
      margin: 16px 0 0 0;
    }
  }

  .print-split-box {
    & > div {
      padding: 16px;
      background-color: #f7f7f7;
      border-radius: 5px;
    }

    @include landscape {
      flex: 0 0 42%;
      margin: 0 0 0 8%;
    }
  }

  .terms {
    margin: 20px 0 0 0;

    .draft-controls.with-controls .controls-actions {
      margin: 10px 10px 10px 0;
    }

    .terms-list {
      & > ul {
        list-style-type: disc;
        margin: 8px 0 0 1em;
      }
    }

    .term {
      margin: 0 0 10px 0;
    }
  }

  .address-list {
    margin: 8px 0 0 0;

    li {
      margin: 2px 0;
    }
  }

  .second-page {
    padding: 40px 0 0 0;

    .logo {
      display: none;
    }
  }

  .remove-btn,
  .edit-btn {
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--link);
    height: 36px;
    width: 36px;
    border-radius: 18px;
    padding: 8px;
    box-shadow: 0 4px 16px -7px #326aa7;

    svg {
      fill: white;
      pointer-events: none;
    }
  }

  .remove-btn {
    padding: 10px;

    line {
      stroke: white;
      stroke-width: 2px;
    }
  }

  .print-button,
  .save-new-btn,
  .new-btn,
  .save-btn {
    background-color: var(--link);
    color: white;
    line-height: 36px;
    height: 36px;
    width: 100%;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
  }
}

@media print {
  @page {
    // size: A4; /* DIN A4 standard, Europe */
    size: auto;
    margin: 0 10mm !important;
  }

  .page-break {
    clear: both;
    page-break-before: always;
  } /* page-break-after works, as well */

  .offert {
    font-size: 12px;

    .new-action,
    .save-btn,
    .remove-btn,
    .edit-btn {
      display: none;
    }

    .second-page {
      .logo {
        display: block;
      }
    }

    .hidden {
      display: none;
    }
  }

  html {
    margin: 0px !important;
  }

  body {
    margin: 0mm 0mm 0mm 0mm;
  }

  .mobile-menu,
  #curtain,
  footer,
  header {
    display: none;
  }

  .print-button {
    display: none;
  }
}
