@import "../ui/media.scss";

.form {
  margin: 80px 0 0 0;
  overflow: hidden;

  .relative {
    position: relative;
  }

  .form-overlay {
    width: calc(100% + 32px);
    height: 100%;
    position: absolute;
    top: 0;
    left: -16px;
    background-color: #f7f7f7;
    z-index: 30;
    padding: 20px 16px;
    transition: opacity 300ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
  }

  &.saved {
    .form-overlay {
      opacity: 1;
      pointer-events: all;
    }
  }

  .wrapper {
    background-color: #f7f7f7;
    border-top: 1px solid #eaeaea;
  }

  form {
    max-width: $tablet;
    margin: 0 auto;
    display: block;
    padding: 0 0 50px 0;

    @include tablet {
      padding: 10px 0 80px 0;
    }
  }

  .details {
    @include tablet {
      display: flex;
      justify-content: space-between;
    }

    .input-text {
      margin: 16px 0;
      display: block;

      @include tablet {
        flex: 0 0 calc(50% - 30px);
        margin: 24px 0 10px 0;
      }
    }
  }

  .status-button {
    margin: 20px 0 0 0;

    @include tablet {
      margin: 40px 0 0 0;
    }
  }
}
